body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --color-grisOscuro: #666;
  /* --color-naranja: #ffa500; */
  --color-naranja: #ca2228;
  --color-azul: #001149;
}

/*  */

.logo {
  height: 40px;
}

.input {
  border-radius: 5px;
}

.ant-layout-sider-trigger {
  background-color: #fff !important;
  color: #1890ff !important;
  font-weight: bold !important;
}

.botonCard {
  color: #ca2228;
  margin-top: 2px;
  width: 80px;
  float: right;
  text-align: center;
  cursor: pointer;
  opacity: 0.5;
}

.botonCardLeft {
  color: #ca2228;
  margin-top: 2px;
  width: 100px;
  text-align: center;
  cursor: pointer;
  opacity: 0.5;
}

.botonCardLeft2 {
  color: #ca2228;
  margin-top: 2px;
  text-align: center;
  cursor: pointer;
  opacity: 0.5;
  width: 100%;
}

.marker {
  display: block;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  padding: 0;
  background-color: #000;
  width: 10px;
  height: 10px;
}

.botonCard:hover,
.botonCardLeft:hover,
.botonCardLeft2:hover {
  border-radius: 5px;
  background-color: #ddd;
  opacity: 1;
}

.card__titulo {
  margin-left: 15px;
  margin-bottom: 5px;
  color: var(--color-naranja);
  font-weight: 500;
  font-size: 20px;
}

.card__accion {
  text-align: right;
  padding-bottom: 5px;
  padding-right: 15px;
}

.card__contenido {
  width: 100%;
  padding: 10px;
  background-color: #fff;
  box-shadow: 1px 1px 5px #ddd;
  border-radius: 5px;
}


.label2{
  color: #666;
  font-weight: 600;
  font-size: 12px;
}

.inputLabel {
  color: var(--color-grisOscuro);
  margin-left: 10px;
  color: teal;
  font-weight: 600;

}

.botonSubmit {
  color: #fff;
  background-color: var(--color-naranja);
  border: 1px solid var(--color-naranja) !important;
  border-radius: 5px;
}

.botonSubmit:hover {
  color: var(--color-naranja);
  background-color: #fff;
  border: 1px solid var(--color-naranja) !important;
}

.botonSubmit:disabled {
  border: 0px solid var(--color-naranja) !important;
}

.eyeIcon {
  opacity: 0.5;
  cursor: pointer;
}

.eyeIcon:hover {
  opacity: 1;
}

.tituloGrid {
  text-align: center;
  font-weight: 600;
}

.labelGrid {
  text-align: right;
  font-weight: 600;
}

.imgPosicion {
  height: 110px;
  margin-top: 25px;
}

.textoNaranja {
  font-weight: 600;
  color: var(--color-naranja);
}

.renglonArticulo:hover {
  background-color: #f1f1f1;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 5px 0;
}

.rowItem {
  padding: 15px 15px;
  border-bottom: 1px solid #f1f1f1;
  color: #666;
  cursor: pointer;

  background: url(/src/assets/chevron-right.png) no-repeat;
  background-size: 20px 20px;
  background-position: right 10px bottom 15px;
  border-radius: 5px;
  margin: 0px 0;
}

.rowItem:hover,
.rowItemElemento:hover {
  background-color: #fcfcfc;
}

.selectedItem,
.selectedItem:hover {
  background-color: #f1f1f1;
}

.rowItemElemento {
  padding: 15px 15px;
  border-bottom: 1px solid #f1f1f1;
  color: #666;
  border-radius: 5px;
}

.rowItem:last-of-type,
.rowItemElemento:last-of-type {
  border-bottom: 0px solid #f1f1f1;
}

.escojaCategoria {
  text-align: center;
  padding: 50px 0;
  color: #666;
}

.ver {
  color: #f1f1f1;
  font-size: 12px;
}

.IconCheck {
  font-size: 25px;
  color: #666;
}

.IconCheck:hover {
  color: var(--color-naranja);
}

.valor {
  padding: 2px 5px;
  background-color: #f1f1f1;
  border-radius: 5px;
  height: 25px;
}

.fileItem {
  cursor: pointer;
}

.fileItem:hover {
  background-color: #f1f1f1;
  color: var(--color-naranja);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
}

.tituloLogin {
  text-align: center;

  color: #f1f1f1;
  font-weight: 500;
  font-size: 20px;
}

.full {
  height: 100vh;
  background-color: red;
  padding-top: 100px;
  background: url(/src/assets/backlogin.jpg);
  background-repeat: no-repeat;
  background-size: auto;
}

.loginCuadro {
  width: 350px;
  background-color: rgba(7, 60, 85, 0.5);
  padding: 20px 50px;
  border-radius: 50px;
  margin-left: 200px;
}

.historialVentana {
  font-size: 11px;
  overflow-x: hidden;
  max-height: 270px;
  overflow-y: scroll;
}

.logoLogin {
  height: 60px;
}

.logoContainer {
  padding-left: 270px;
  margin-bottom: 15px;
}

.campaniaItem {
  font-weight: 600;
  padding: 4px;
}

.campaniaItem:hover {
  color: orange;
}

.subTitulo {
  margin-bottom: 5px;
  color: #ca2228;
  font-weight: 600;
}

.infoMapa {
  border-radius: 3px;
  background-color: rgba(100, 100, 100, 0.7);
  color: white;
  font-weight: 600;
  padding-left: 10px;
  margin-left: 5px;
  margin-right: 5px;
}

.direccionMapa {
  position: relative;
}

.contadorResultados {
  font-size: 20px;
  color: #ca2228;
  margin-bottom: 10px;
}

.renglonTabla {
  cursor: pointer;
}

.renglonTabla:hover {
  color: #ca2228;
}

.preguntasGNP {
  margin-top: 3px;
  width: 120px;
  padding: 1px;
  background-color: #ca2228;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  font-size: 12px;
  cursor: pointer;
}

.esperando {
  animation: blinker 2s linear infinite;
  color: #fff;
  background-color: #ca2228;
  width: 150px;
  text-align: center;
  border-radius: 10px;
  margin-bottom: 1px;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.line-30 {
  height: 30px;
  line-height: 30px;
}

.itemSitios {
  cursor: pointer;
}

.itemSitios:hover {
  color: #ccc;
}

.mapaContainer {
  height: 470px;
  width: 100%;
  margin-bottom: 10px;
}

.mapaDataContainer {
  z-index: 9999;
  position: "relative";
  width: "90%";
  padding-left: 10px;
}

.tituloColumna {
  padding-left: 5px;
  border-bottom: 1px solid #f1f1f1;
}

.sinServicios {
  text-align: center;
  margin-top: 10px;
  padding: 15px;
  font-weight: 600;
}

.renglon {
  padding: 2px 0 2px 5px;
  cursor: pointer;
  margin-bottom: 5px;
}

.renglon:hover {
  color: red;
}

.botonCancelar {
  margin-left: auto;
  margin-right: auto;
  height: 70px;
  width: 70px;
  line-height: 65px;
  text-align: center;
  border: 1px solid #f1f1f1;
  border-radius: 50%;
  cursor: pointer;
  font-weight: 600;
  color: #ca2228;
}

.botonCancelar:hover,
cancelado {
  color: #f1f1f1;
  background-color: #ca2228;
}

.marcarStep {
  cursor: pointer;
}

.marcarStep:hover {
  color: #ca2228;
}

.errorCampo {
  padding-left: 5px;
  color: #ca2228;
  font-weight: 600;
  font-size: 12px;
  padding-top: 3px;
}

.botonRecursos {
  padding: 40px;
  text-align: center;
  background-color: #001149;
  color: #fff;
  font-size: 25px;
  border-radius: 20px;
  cursor: pointer;
}

.botonRecursos:hover {
  background-color: #1d3278;
}

.siniestros {
  padding: 25px;
  border-radius: 15px;
  font-size: 50px;
  text-align: center;
}

.tieneSineistros {
  background-color: yellow;
  color: red;
}

.sinSineistros {
  background-color: green;
  color: #fff;
}

.ultimaVerificacion {
  text-align: center;
  font-size: 30px;
  color: #999;
}

.semaforo__titulo {
  text-align: center;
  font-weight: 600;
  font-size: 20px;
  background-color: #001149;
  border-radius: 10px;
  color: #fff;
  padding: 5px 0;
}

.folioServicio {
  font-size: 23px;
  color: #ca2228;
}

.semaforo__renglon {
  padding: 5px 5px;
  margin: 5px 0;
  font-size: 12px;
  border-radius: 10px;
  cursor: pointer;
}
.semaforo__renglon__blink {
  animation: blinker 0.5s linear infinite;
  background-color: #f9f54b;
  color: red;
  border-radius: 10px;
  padding: 5px 5px;
  margin: 5px 0;
  font-size: 12px;
  cursor: pointer;
}

.semaforo__renglon:hover {
  border: 3px solid #999;
}

.semaforo__verde {
  background-color: #baffcd;
  border: #baffcd 3px solid;
}

.semaforo__blanco {
  background-color: #fff;
  border: 3px solid #fff;
}

.semaforo__amarillo {
  background-color: #f8ffba;
  border: #f8ffba 3px solid;
}

.semaforo__rojo {
  background-color: #ffbaba;
  border: #ffbaba 3px solid;
}

.labelPoliza {
  color: teal;
}

.resultadoPolziario {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 5px;
  width: 98%;
  cursor: pointer;
}

.resultadoPolziario:hover {
  border: 1px solid #333;
}

.copiar {
  cursor: pointer;
}

.copiar:hover {
  color: #ca2228;
}

.rowAsistencias {
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
}

.rowAsistencias:hover {
  border: 1px solid #333;
}

.inputBusca {
  border: 1px solid #ccc;
  border-radius: 2px;

  padding: 7px 15px;
  font-size: 16px;
}

.childfix {
  position: relative;
  top: 50px;
  left: 190px;
  z-index: 999;
}

.esGnpApi {
  border: 1px solid #999;
  background-color: #999;
  border-radius: 5px;
  padding: 0 3px;
  color: #fff;
}

._300 {
  width: 300px;
}

._350 {
  width: 350px;
}

._450 {
  width: 450px;
}

._550 {
  width: 450px;
}

.blink_text {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.TableRow {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 5px;
  cursor: pointer;
}

.TableRowSimple {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 5px;
}

.TableRow:hover,
.TableRowSimple:hover {
  border: 1px solid #999;
}

.TableRowSelected {
  border: 1px solid #f1f1f1;
  background-color: #f9f9f9;
  color: #333;
  font-weight: 600;
}

.TituloTabla {
  font-weight: 600;
  padding: 5px;
  border-bottom: 1px solid #ccc;
  margin-bottom: 10px;
}

.rowActividades {
  padding: 2px;
  border: 1px solid #ccc;
  background-color: #f1f1f1;
  border-radius: 5px;
  margin-bottom: 5px;
  font-size: 12px;
  color: #333;
  width: 95%;
}

.cajaPropuesta {
  border: 1px solid #ccc;
  border-radius: 5px;
}


.cajaPropuesta_Titulo {
  background-color: #ccc;
  padding: 5px;
  border: 2px solid #ccc;
  font-weight: 600;
  color: #fff;
  text-align: center;
  cursor: pointer;
  font-size: 20px;
}

.cajaPropuesta_Titulo:hover {
  color: #333;

}

.cajaPropuesta_Propuestas {
  padding: 10px;
}


.ProveedorActivo_Caja {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 5px;
  cursor: pointer;
  height: 100px;
}

.ProveedorActivo_Nobre {
  font-weight: 600;
  font-size: 18px;
  color: #333;
  text-align: center;
}

.ProveedorActivo_UltimoAcceso {
  font-size: 12px;
  color: #666;
  text-align: center;
}

.ProveedorAtivo_Circulo {
  width: 80%;
  height: 20px;
  border-radius: 10px;

  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
}

.tituloModal {
  font-weight: 600;
  font-size: 20px;
  color: #ca2228;

}